.signup {
  display: grid;
}

.signup h2 {
  color: #6FB9FF;
}

.signup .center {
  text-align: center;
}

.signup button {
  margin-left: auto;
  margin-right: auto;
}

.signup-container {
  margin: auto;
  width: 30rem;
  place-self: center;
}
.signup-info-box {
  border: 1px solid #aeb3b7;
  box-shadow: 0 2px 6px rgb(13 26 38 / 15%);
}

.signup-form {
  padding: 2rem;
}

.terms-checkbox {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.signup-form a {
  text-decoration: underline;
}

.amplify-textfield {
  padding-bottom: 2rem;
}

.amplify-textfield:last-child {
  padding-bottom: 0rem;
}

.amplify-field__error-message {
  color: red;
}