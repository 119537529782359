.amplify-button[data-variation='primary'] {
  background: #0071f6cc;
}

.amplify-button[data-variation=link] {
  color: #0071f6cc;
}
.amplify-button[data-variation=link]:hover {
  color: #0071f6cc;
}

.amplify-button[data-variation='primary']:hover {
  background: #0071f688;
}

.amplify-input:focus {
  border-color: #0071f6cc;
  box-shadow: 0px 0px 0px 1px #0071f6cc;
}
