/* General */

.markdown-renderer * {
  box-sizing: border-box;
}

.markdown-renderer {
  -ms-text-size-adjust: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  color: #24292e;
  line-height: 1.5;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  padding: 12px 24px;
}

/* Tables */

.markdown-renderer table {
  overflow: auto;
  border-spacing: 0;
  display: block;
  border-collapse: collapse;
  width: 100%;
  margin-top: 0;
  margin-bottom: 16px;
}

.markdown-renderer table td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

.markdown-renderer table th {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
  font-weight: 600;
}

.markdown-renderer table tr {
  border-top: 1px solid #c6cbd1;
  background-color: #fff;
}

.markdown-renderer table tr:nth-child(2n) {
  background-color: #f6f8fa;
}

/* Anchor */

.markdown-renderer a {
  background-color: transparent;
  color: #0366d6;
  text-decoration: none;
}

.markdown-renderer a:active {
  outline-width: 0;
}

.markdown-renderer a:hover {
  outline-width: 0;
  text-decoration: underline;
}

.markdown-renderer a:not([href]) {
  text-decoration: none;
  color: inherit;
}

/* Headlines */

.markdown-renderer h1 {
  margin-bottom: 16px;
  margin-top: 24px;
  padding-bottom: 8px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.25;
  border-bottom: 1px solid #eaecef;
}

.markdown-renderer h2 {
  margin-bottom: 16px;
  margin-top: 24px;
  padding-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  border-bottom: 1px solid #eaecef;
}

.markdown-renderer h3 {
  margin-bottom: 16px;
  margin-top: 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25;
}

.markdown-renderer h4 {
  margin-bottom: 16px;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdown-renderer h5 {
  margin-bottom: 16px;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.25;
}

.markdown-renderer h6 {
  margin-bottom: 16px;
  margin-top: 24px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25;
  color: #6a737d;
}

/* Lists */

.markdown-renderer ul, .markdown-renderer ol {
  margin-top: 0;
  margin-bottom: 16px;
  padding-left: 24px;
}

.markdown-renderer li {
  margin-bottom: 0;
  margin-top: 0;
}

.markdown-renderer ol ol,
.markdown-renderer ul ol,
.markdown-renderer ol ul,
.markdown-renderer ul ul {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: lower-roman;
}

.markdown-renderer ol ol ol,
.markdown-renderer ol ul ol,
.markdown-renderer ul ol ol,
.markdown-renderer ul ul ol,
.markdown-renderer ol ol ul,
.markdown-renderer ol ul ul,
.markdown-renderer ul ol ul,
.markdown-renderer ul ul ul {
  list-style-type: lower-alpha;
}

/* Code */
.markdown-renderer code {
  background-color: rgba(27, 31, 35, .05);
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 85%;
  margin: 0;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
}
